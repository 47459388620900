import '../css/01.settings/fonts.css';
import '../css/02.generic/style.css';
import '../css/03.objects/container.css';
import '../css/03.objects/buttons.css';
import '../css/03.objects/measures.css';
import '../css/03.objects/section.css';
import '../css/03.objects/grid.css';
import '../css/03.objects/card.css';
import '../css/03.objects/forms.css';
import '../css/03.objects/texts.css';
import '../css/03.objects/tabs.css';
import '../css/03.objects/lists.css';
import '../css/04.components/header.css';
import '../css/04.components/cookies-consent.css';
import '../css/04.components/footer.css';
import '../css/04.components/modal.css';
import '../css/04.components/hint.css';
import '../css/04.components/slider.css';
import '../css/04.components/blog-section.css';
import '../css/04.components/socials.css';
import '../css/04.components/partnership.css';
import '../css/05.trumps/backgrounds.css';
import '../css/05.trumps/custom.css';
import './utils/cookies-consent.js';
import './func/tabs.js';
import './func/swiper.js';

import { initModal } from './func/modal';
import handleFormSubmit from './func/contact-form.js';
import { arrowSlider, handleSlides } from './func/slider';

const sliderTriggerWidth = 1025;
const menuTriggerWidth = 1067;

window.addEventListener('DOMContentLoaded', run);

async function run(e) {
    arrowSlider({ wrapper: '#featured .slider-wrapper', autoSlide: 30000 });
    initModal({ modalClass: '.booking-calendar', triggerBtnClass: '.calendar-popup' });
    initModal({ modalClass: '.privacy', triggerBtnClass: '.privacy-policy' });
    initModal({ modalClass: '.terms', triggerBtnClass: '.terms-of-use' });

    handleFormSubmit({
        selector: '.contact-form',
        uri: 'https://contact.zaia.dev/api/contact'
    });

    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
            toggleMobileMenu(false);
        } else if (e.target.classList.contains('icon-box')) {
            toggleMobileMenu();
        } else if (e.target.classList.contains('dropbtn')) {
            toggleDropDownMenu();
        }
    });
}

function toggleMobileMenu(state) {
    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.header');
    const condition = hasState ? !!state : !header.classList.contains('nav-visible');

    condition ? header.classList.add('nav-visible') : header.classList.remove('nav-visible');
}

function toggleDropDownMenu(state) {
    if (window.innerWidth < menuTriggerWidth) {
        const hasState = typeof state !== 'undefined';
        const header = document.querySelector('.dropdown');
        const condition = hasState ? !!state : !header.classList.contains('show');

        condition ? header.classList.add('show') : header.classList.remove('show');
    }
}

function scrollTo(e) {
    typeof e.preventDefault === 'function' && e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    // element.scrollIntoView({ behavior: 'smooth' });
    scrollToElement(element);
}

function scrollToElement(element, behavior = 'smooth') {
    const scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const finalOffset = element.getBoundingClientRect().top + scrollTop;

    window.scrollTo({
        top: finalOffset,
        behavior
    });
}

function updateSlidesStructure(parentSelector) {
    if (window.innerWidth < sliderTriggerWidth) {
        const itemsPerSlide = 6;
        const items = Array.from(
            document.querySelectorAll(parentSelector + ' .featured-item')
        ).filter(x => x.style.display != 'none');

        {
            document
                .querySelectorAll(parentSelector + ' .slide')
                .forEach(item => item.replaceWith(...item.childNodes));
        }

        while (items.length) {
            // grab 1st itemsPerSlide items and wrap then in <div class="slide">
            const slideChildren = items.splice(0, itemsPerSlide);
            const slide = document.createElement('div');
            slide.classList.add('slide');
            if (slideChildren.length) {
                if (slideChildren[0].parentElement.classList.contains('slide')) {
                    continue;
                }
                slideChildren[0].parentElement.insertBefore(slide, slideChildren[0]);
                for (let i = 0; i < slideChildren.length; i++) {
                    slide.appendChild(slideChildren[i]);
                }
            }
        }
    }
}
